export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon'
      },
      {
        title: 'Second page',
        route: 'second-page',
        icon: 'FileIcon'
      }
    ]
  }
]
